
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import {ElPagination, ElLoading, ElEmpty} from 'element-plus';

import {IPage} from '@/model/index';

@Options({
  components: {
    ElPagination,
    ElLoading,
    ElEmpty
  }
})
export default class Proxy extends Vue {
  @Prop()
  titleList!: {name: string; key?: string}[];
  @Prop()
  list!: any[];
  @Prop()
  table!: {keys: string[]};
  @Prop()
  pagination!: IPage;
  @Prop()
  layout!: string;
  @Prop()
  titleClass!: string;
  @Prop()
  loading!: boolean;

  pager = {};

  created() {
    console.log(this, 'table');
    if (!this.layout) {
      this.layout = 'prev, pager, next';
    }
  }

  showUnerLine(flag: boolean) {
    return flag ? 'underline' : '';
  }
  showPointer(flag: boolean) {
    return flag ? 'u_c--pointer' : '';
  }
  get count() {
    return 5;
  }

  get computedClass() {
    let _class = 'list-title-box';
    if (this.$attrs.class) {
      _class += ` ${this.$attrs.class}`;
    }
    if (this.titleClass) {
      _class += ` ${this.titleClass}`;
    }
    return _class;
  }

  handleSizeChange(limit: number) {
    console.log('limit: ', limit);
    this.$emit('change', {
      page: 1,
      limit
    });
  }
  handlePageChange(page: number) {
    console.log('page: ', page);
    this.$emit('change', {
      page: page,
      limit: this.pagination.pageLimit
    });
  }

  handleTrClick(item: unknown) {
    this.$emit('click', item);
  }
}
