<template>
  <div :class="computedClass">
    <div :class="['title']" v-for="(title, idx) in titleList" :key="title.key || idx">
      <slot :name="title.key">
        {{ /：/.test($t(title.name)) ? $t(title.name).replace(/：/, '') : $t(title.name) }}
      </slot>
    </div>
  </div>

  <div
    v-loading="loading"
    :element-loading-text="$t('ui_loading_hard')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-if="list && list.length"
  >
    <div :class="['list-item', showPointer(v.detail)]" v-for="(v, id) in list" :key="id">
      <template v-for="(key, idx) in table.keys" :key="idx">
        <div
          :class="['title', showUnerLine(key === 'userName' && v.detail)]"
          @click="handleTrClick(v)"
        >
          <slot :item="{value: v[key], key: key, item: v}">
            {{ v[key] }}
          </slot>
        </div>
      </template>
    </div>
    <slot name="sum"> </slot>
  </div>
  <el-empty v-else :description="$t('ui_no_data_yet')"></el-empty>
  <div class="footer">
    <el-pagination
      background
      :layout="layout || 'prev, pager, next'"
      :total="pagination.totalNumber"
      :pager-count="5"
      :page-count="pagination.pageLimit"
      :page-sizes="[10, 20, 50]"
      :page-size="pagination.pageLimit"
      :current-page="pagination.page"
      @size-change="handleSizeChange"
      @current-change="handlePageChange"
      ref="pager"
    >
    </el-pagination>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import {ElPagination, ElLoading, ElEmpty} from 'element-plus';

import {IPage} from '@/model/index';

@Options({
  components: {
    ElPagination,
    ElLoading,
    ElEmpty
  }
})
export default class Proxy extends Vue {
  @Prop()
  titleList!: {name: string; key?: string}[];
  @Prop()
  list!: any[];
  @Prop()
  table!: {keys: string[]};
  @Prop()
  pagination!: IPage;
  @Prop()
  layout!: string;
  @Prop()
  titleClass!: string;
  @Prop()
  loading!: boolean;

  pager = {};

  created() {
    console.log(this, 'table');
    if (!this.layout) {
      this.layout = 'prev, pager, next';
    }
  }

  showUnerLine(flag: boolean) {
    return flag ? 'underline' : '';
  }
  showPointer(flag: boolean) {
    return flag ? 'u_c--pointer' : '';
  }
  get count() {
    return 5;
  }

  get computedClass() {
    let _class = 'list-title-box';
    if (this.$attrs.class) {
      _class += ` ${this.$attrs.class}`;
    }
    if (this.titleClass) {
      _class += ` ${this.titleClass}`;
    }
    return _class;
  }

  handleSizeChange(limit: number) {
    console.log('limit: ', limit);
    this.$emit('change', {
      page: 1,
      limit
    });
  }
  handlePageChange(page: number) {
    console.log('page: ', page);
    this.$emit('change', {
      page: page,
      limit: this.pagination.pageLimit
    });
  }

  handleTrClick(item: unknown) {
    this.$emit('click', item);
  }
}
</script>

<style lang="scss" scoped>
.u_t--center {
  text-align: center;
}
.u_c--pointer {
  cursor: pointer;
}
.list-title-box {
  height: 40px;
  font-weight: 700;
  @extend .flex;
  @extend .flex-center;
  font-size: 12px;
  color: #333333;
  padding: 12px /* 12/25 */ 0;
  .title {
    @extend .flex;
    @extend .flex-center;
    @extend .flex-justify-center;
    @extend .u_t--center;
    @extend .flex-wrap;
    flex: 1;
  }
}
.list-item {
  height: 48px;
  display: flex;
  font-size: 12px;
  color: #333333;
  border-bottom: 1px /* 1/25 */ solid #eeeeee;
  &:hover > div {
    background-color: #fdf7ec;
  }
  & > div {
    @extend .flex;
    @extend .flex-center;
    @extend .flex-justify-center;
    @extend .u_t--center;
    @extend .flex-wrap;
    flex: 1;
  }
  .title {
    .content {
      @extend .over2;
    }
    &.underline {
      color: $bg;
      text-decoration: underline;
    }
  }
}
.footer {
  margin-top: 10px;
  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: $bg;
  }
}
.background-th {
  background-color: #c5d5ff;
}
.text-day {
  color: $cf;
}
</style>
